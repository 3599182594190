import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'appFlags',
  standalone: true,
})
export class FlagsPipe implements PipeTransform {

  transform(value: string): string {

    if (value) {
      return 'assets/images/flags/' + value.toLowerCase().replaceAll(" ", "-") + '-flag.svg';
    } 
    
    return 'assets/images/flags/world.svg'
  }

}
