import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private _config: any;

  constructor() {
    this._config = environment
  }

  // Gets API route based on the provided key
  get getBaseUrlApi(): string {
    return this._config.MAIN_API_URL
  }

  get getHubBaseUrlApi(): string {
    return this._config.HUB_API_URL
  }

  get(key: any) {
    return this._config[key];
  }
}
